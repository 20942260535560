import {Tooltip, Modal, Alert} from 'bootstrap';

import '@/scss/base';
import '@/scss/print';
import '@/scss/components/uppy';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/browser';
import {BrowserTracing} from '@sentry/tracing';
import {
  asCurrency, formatAddress, generateUUID, formatInteger,
} from '@canopyllc/roots/utils/stringUtil.js';
import {copyHTMLToClipboard} from '@canopyllc/roots/utils/browser.js';

/**
 * @deprecated - don't use jquery for any new code or weaver will cut you
 */
import jQuery from 'jquery';
import {snackbar} from '@canopyllc/roots/utils/sweetAlert2.js';

// Setup svg sprite - configured in vite.config.mjs
// eslint-disable-next-line import/no-unresolved
import 'virtual:svg-icons-register';

/**
 * ------------------------------------------------------------------------
 * Bootstrap Elements
 * ------------------------------------------------------------------------
 */
// Tooltips - Read more on how to use tooltips here: https://getbootstrap.com/docs/5.0/components/tooltips/
const tooltips = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
tooltips.map((triggerEl) => new Tooltip(triggerEl));
window.Tooltip = Tooltip; // expose it on window for v-tooltip to use

// Alerts
let alertList = document.querySelectorAll('.alert');
alertList.forEach((alert) => {
  new Alert(alert);
});

// Modal
window.Modal = Modal; // expose it on window for v-tooltip to use

/**
 * FONT AWESOME AND SVG ICON SETUP
 */
// Setup font awesome icons
// faTimes & faCheckCircle are used in agency search from roots
// @see https://fontawesome.com/v6/docs/web/use-with/react/add-icons#add-icons-globally for info on adding ALL icons in fas package
library.add(fas);
dom.watch(); // replace <i> tags with <svg>

/**
 * @deprecated
 */
window.$ = jQuery;
window.jQuery = jQuery;

/**
 * Alert Messages
 */
function alertMessages(messages) {
  messages.forEach((message) => {
    snackbar({text: message.message, type: message.type});
  });
}
window.alertMessages = alertMessages;

/**
 * Context needed in html templates
 */
window.app_context = {
  asCurrency,
  formatAddress,
  formatInteger,
  generateUUID,
};

/**
 * Initialize Sentry
 */
// The __INSTANCE_SLUG__ and __INSTANCE_VERSION__ are defined in vite
// eslint-disable-next-line no-undef
if (__INSTANCE_SLUG__ !== 'localhost') {
  Sentry.init({
    // Sentry JS Docs: https://docs.sentry.io/platforms/javascript/
    // I used the DNS from here: https://sentry.io/settings/canopyteam/projects/n-pears/keys/
    // TODO: Verify that this is okay to be public
    dsn: 'https://c09fdec0464549ffa8dcc19ac64122c6@o13717.ingest.sentry.io/6185679',

    // eslint-disable-next-line no-unused-vars
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({eventId: event.event_id});
      }
      return event;
    },

    // eslint-disable-next-line no-undef
    release: `fns-${__INSTANCE_SLUG__}@${__INSTANCE_VERSION__}`,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

function copyIssueData(templateType) {
  const issueDataElem = document.getElementById('view_meta_data'),
        templateElem = document.getElementById(`jira_${templateType}_template`);
  let html = '';
  if (templateType === 'meta_data') {
    html = issueDataElem.innerHTML;
  } else {
    html = templateElem.innerHTML + issueDataElem.innerHTML;
  }
  copyHTMLToClipboard(html);
}

const copyIssueBtns = document.querySelectorAll('[data-template-type]');
copyIssueBtns.forEach((btn) => {
  btn.addEventListener('click', (e) => {
    copyIssueData(e.target.dataset.templateType);
    e.preventDefault();
  });
});
